<template>
  <div class="container-full banner">
    <div class="container content">
      <div class="content-l">
        <div class="content-l-t">
          <div>分站开通</div>
          <div>拥有属于自己的网站</div>
        </div>
        <div class="content-l-b">独立后台自由管理 · 24小时自动售单 · 无需人工干预</div>
      </div>
      <div class="content-r">
        <div class="content-r-t">
          <span @click="accountLogin" class="number" :class="{'title-active': loginMode}">账号登录</span>
          <span @click="smsLogin" class="message" :class="{'title-active': !loginMode}">短信登录</span>
        </div>
        <div>
          <!-- 账号登录 -->
          <sign-up-input v-if="loginMode" @form-data="getFormData"/>
          <!-- 短信登录 -->
          <sign-up-input v-else :numOrSms="false" @form-data="getFormData" @get-code="getCode"/>
          <!-- 登录 -->
            <div class="btn-box">
              <el-button class="btn" @click="login">登录</el-button>
            </div>
          <div class="actions_item">
            <router-link to="/register">立即注册</router-link>
            <span v-show="loginMode == true">|</span>
            <router-link to="/rePwd" v-show="loginMode == true">忘记密码</router-link>
            <!-- <router-link to="/rePwd" v-show="loginMode == true">忘记密码</router-link> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SignUpInput from 'components/signUpInput/SignUpInput.vue'
import { login, getCode, mobilelogin } from 'network/api'

export default {
  components: {
    SignUpInput
  },
  data () {
    return {
      loginMode: true, // 账号登录为true,短信登录为false
      formData: {
        account: '',
        password: '',
        code: '',
        accountBoole: null,
        passwordBoole: null,
        codeBoole: null
      }
    }
  },
  methods: {
    accountLogin () {
      this.loginMode = true
    },
    smsLogin () {
      this.loginMode = false
    },
    getFormData (data) {
      this.formData = data
    },
    // 登录
    login () {
      if (this.loginMode) {
        login(this.formData.account, this.formData.password, this.$appkey).then(res => {
          if (res.code === 1) { // 登录成功
            this.$notify({
              title: '欢迎',
              message: res.msg,
              type: 'success'
            })
            // 存储登录信息
            const userInfo = res.data.userinfo
            this.$store.dispatch('login', userInfo)
            // 跳转
            const _this = this
            setTimeout(function () {
              _this.$router.push('/orderAdmin/purchase')
            }, 2000)
          } else if (res.code === 0) { // 登录失败
            this.$notify.error({
              title: '错误',
              message: res.msg
            })
          }
        })
      } else {
        mobilelogin(this.formData.account, this.formData.code, this.$appkey).then(res => {
          if (res.code === 1) { // 登录成功
            this.$notify({
              title: '欢迎',
              message: res.msg,
              type: 'success'
            })
            // 存储登录信息
            const userInfo = res.data.userinfo
            this.$store.dispatch('login', userInfo)
            // 跳转
            const _this = this
            setTimeout(function () {
              _this.$router.push('/orderAdmin/purchase')
            }, 2000)
          } else if (res.code === 0) { // 登录失败
            this.$notify.error({
              title: '错误',
              message: res.msg
            })
          }
        })
      }
    },
    // 获取验证码
    getCode () {
      getCode(this.formData.account, 'mobilelogin', this.$appkey).then(res => {
        this.$message({
          message: res.msg,
          type: 'success'
        })
      })
    }
  }
}
</script>

<style scoped src="styles/views/login/loginbanner.css">
</style>
