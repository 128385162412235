import { render, staticRenderFns } from "./LoginBanner.vue?vue&type=template&id=546346c6&scoped=true&"
import script from "./LoginBanner.vue?vue&type=script&lang=js&"
export * from "./LoginBanner.vue?vue&type=script&lang=js&"
import style0 from "styles/views/login/loginbanner.css?vue&type=style&index=0&id=546346c6&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "546346c6",
  null
  
)

export default component.exports