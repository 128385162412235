<template>
  <div class="footer">
    <div>© 2020 {{$store.getters.getSiteInfo.name}} , All Rights Reserved {{$store.getters.getSiteInfo.beian}}</div>
  </div>
</template>

<script>
export default {
}
</script>

<style scoped src="styles/views/login/loginfooter.css">
</style>
